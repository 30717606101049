<template>
  <module
    ref="module"
    id="strategyDetail"
    titleIcon="fa fa-bar-chart-o"
    :title="isNewStrategy ? this.txt.titleNewStrat : this.txt.titleEdit"
    :use-default-list="false"
  >
    <div slot="toolbar-global">
      <button class="btn btn-default fix-margin" v-on:click="prevPage()">
        <i class="nc-icon nc-minimal-left"></i>
        {{ $t('back') }}
      </button>
    </div>
    <div id="strategyDetails" slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div class="row" v-if="inprogress == false && modal_alert">
        <div class="col-md-12">
          <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
            <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                    v-on:click="closeModal">
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-12" v-if="inprogress == false">

        <div v-if="updateinprogress" class="text-center">
          <h2>{{ $t('submitting') }}</h2>
          <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
        </div>

        <div v-if="!updateinprogress">
          <div class="row" v-if="strategyVisibilityResponse && !pubs">
            <div class="col-md-12">
              <div class="alert alert-info">
                <i class="fa fa-info-circle"></i>
                {{ strategyVisibilityResponse }}
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-md-6">
              <span class="category sub-text text-danger mb-2" v-if="alert_stratname">
                <span v-if="alert_msg">
                  {{ alert_msg }}
                </span>
                <span v-else>
                  {{ $t('charnotallowed') }} {{ $t('onlyalphanumerics') }}
                </span>
              </span>
              <label> {{ txt.helperStratName }} </label>
              <fg-input
                v-model="stratname"
                replace="[^0-9a-zA-Z\s]"
                addon-left-icon="nc-icon nc-sound-wave"
                type="text"
                v-validate="modelValidations.strat_name"
                :name="txt.helperStratName"
                :error="getError(txt.helperStratName)"
                :maxlength="24"
                :placeholder="`${$t('helper_stratname')}`"
              ></fg-input>
              <span class="category sub-text mb-2" style="display: flex;">
                {{ $t('onlyalphanumerics_strategy') }}
              </span>
            </div>
            <div class="col-md-6">
              <div>
                <label>&nbsp;</label>
                <div class="mt-1">
                  <span style="display: flex;">
                    <div class="info-icon info-l10 info-t5">
                      <el-tooltip :content="`${$t('helper_sharetxt')}`" :open-delay="100" placement="top"
                                  popper-class="adjust-width">
                        <i class="nc-icon nc-alert-circle-i pt-1 pr-1"></i>
                      </el-tooltip>
                    </div>
                    <p-checkbox
                      v-model="pubs"
                      :disabled="(!!strategyVisibilityResponse && !pubs)"
                    >
                      {{ $t('publictxt') }}
                    </p-checkbox>
                  </span>
                </div>
              </div>
            </div>

            <div v-if="pubs" class="row mt-3 mx-0">
              <div class="col-md-12 mt-3">
                <h6 class="mb-1">{{ $t('pricing_public') }} {{ $t('settings') }}</h6>
                <hr>
                </hr>
              </div>
              <div class="col-md-6">
                <span class="label" style="display: flex;">
                  <el-tooltip
                    :content="`${$t('helper_public_pricetxt')}`"
                    :open-delay="100"
                    placement="top"
                    popper-class="adjust-width"
                  >
                    <i class="nc-icon nc-alert-circle-i info-icon mr-1"></i>
                  </el-tooltip>
                  {{ $t('public_pricetxt') }}: {{ stratprice }}%
                </span>
                <span style="display: flex;">
                  <el-select
                    class="py-1 w-100"
                    v-model="stratprice"
                    :placeholder="`${$t('public_pricetxt')}`"
                    style="margin-bottom: 10px;border: 0px;" v-bind:disabled="!pubs"
                  >
                    <el-option
                      class="select-default"
                      v-for="p in percentage"
                      :key="p.prop" :label="p.label" :value="p.prop"
                    ></el-option>
                  </el-select>
                </span>
              </div>
              <div class="col-md-6">
                <span class="label" style="display: flex;">
                  <el-tooltip
                    :content="`${$t('helper_internal_credit')}`"
                    :open-delay="100"
                    placement="top"
                    popper-class="adjust-width"
                  >
                    <i class="nc-icon nc-alert-circle-i info-icon mr-1"></i>
                  </el-tooltip>
                  {{ $t('internal_credit') }}
                </span>
                <span>
                  <el-select
                    class="py-1 w-100"
                    v-model="internal_credit"
                    v-bind:disabled="!canEditBrokerInternal"
                    style="margin-bottom: 10px;border: 0px;"
                  >
                    <el-option value="1" :label="$t('yestxt')"></el-option>
                    <el-option value="0" :label="$t('notxt')"></el-option>
                  </el-select>
                </span>
              </div>
              <div class="col-md-6">
                <span class="label" style="display: flex;">
                  {{ $t('publictxt_alt') }} {{ $t('urltxt') }}
                </span>
                <span style="display: flex;">
                  <fg-input
                    v-model="straturl"
                    addon-left-icon="nc-icon nc-tag-content"
                    type="text"
                    :class="['url-input', isValidUrl ? '' : 'has-danger']"
                    :disabled="!pubs"
                  >
                    <template slot="addonLeft">
                      <i class="nc-icon nc-tag-content mr-1"></i>
                      <span class="pre-url">{{ getPublicUrlPrefix }} &nbsp;&nbsp; </span>
                    </template>
                  </fg-input>
                </span>
                <span class="category sub-text mb-2" style="display: flex;">
                  {{ $t('url_format_txt') }}
                </span>
              </div>
              <div class="col-md-6">
                <span class="label" style="display: flex;">
                  <el-tooltip
                    :content="$t('helper_minimum_balance')"
                    :open-delay="100"
                    placement="top"
                    popper-class="adjust-width"
                  >
                    <i class="nc-icon nc-alert-circle-i info-icon mr-1"></i>
                  </el-tooltip>
                  {{ $t('minimum_balance_text') }}
                </span>
                <span style="display: flex;">
                  <fg-numeric
                    class="w-100"
                    v-model="minimum_balance"
                    :disabled="!pubs"
                    :min="0"
                    :step="1"
                  ></fg-numeric>
                </span>
              </div>
              <div :class="['col-md-12', 'mb-3', isValidDescription ? '' : 'form-control-danger' ]">
                <span class="label" style="display: flex;">
                  {{ $t('description') }}
                </span>
                <span style="display: flex;">
                  <textarea
                    v-model="stratdesc"
                    id="description"
                    :class="['form-control', isValidDescription ? '' : 'form-control-danger']"
                    :placeholder="`${$t('helper_public_desctxt')}`"
                    rows="3" style="padding: 10px;"
                    v-bind:disabled="!pubs"
                  ></textarea>
                </span>
              </div>
            </div>
          </div>
          <div class="row" v-if="adminusr">
            <div class="col-md-12 mt-4">
              <h6>{{ $t('hokotxt') }} {{ $t('feetxt') }} {{ $t('settings') }} </h6>
              <hr>
              </hr>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-sm-6">
                  <span class="label" style="display: flex;">
                    {{ $t('performance_type') }}
                  </span>
                  <el-tooltip :content="$t('performance_type_info')"
                              :open-delay="100"
                              placement="top"
                              popper-class="adjust-width">
                    <span style="display: flex;">
                    <el-select
                      class="py-1 w-100 mb-2"
                      v-model="performance_type"
                      :placeholder="`${$t('performance_type')}`"
                      :disabled="!pubs || !adminusr"
                    >
                      <template slot="prefix">
                        <i class="nc-icon nc-chart-bar-32 p-2 mt-1"></i>
                      </template>
                      <el-option :label="$t('equity')" value="Equity"> </el-option>
                      <el-option :label="$t('balance')" value="Balance"> </el-option>
                    </el-select>
                  </span>
                  </el-tooltip>
                </div>
                <div class="col-sm-6">
                  <span class="label" style="display: flex;">
                    {{ $t('hoko_performance_fee') }}
                  </span>
                  <el-tooltip :content="$t('hoko_performance_fee_info')"
                              :open-delay="100"
                              placement="top"
                              popper-class="adjust-width">
                    <span style="display: flex;">
                      <fg-numeric
                        class="py-1 w-100"
                        v-model="hoko_performance_fee"
                        :min="0.10"
                        :max="100.00"
                        :step="0.10"
                        :precision="2"
                        percentage
                      ></fg-numeric>
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="adminusr">
              <span class="label" style="display: flex;">
                {{ $t('helper_tradedassets') }} (SUPER ADMIN ONLY)
              </span>
              <span class="py-1" style="display: flex;">
                <fg-input
                  v-model="tradedAssets"
                  addon-left-icon="fa fa-keyboard-o"
                  type="text"
                  :placeholder="`${$t('helper_tradedassets')}`"
                ></fg-input>
                <p-button
                  class="ml-3"
                  type="success"
                  v-if="!isNewStrategy"
                  v-loading="assetsLoading"
                  :disabled="assetsLoading"
                  @click.native="addDone()"
                  style="margin-top: 0;"
                >
                  {{ $t('upentrybtn') }} {{ $t('helper_tradedassets') }}
                </p-button>


              </span>
            </div>
          </div>
        </div>
        <div v-if="!updateinprogress" class="text-center">
          <div class="py-3 mx-auto" v-if="isNewStrategy && validatingForm">
            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
          </div>

          <p-button type="success" v-if="isNewStrategy && !validatingForm" @click.native="validateCreateForm()"
                    style="margin-top: 0;">
            {{ $t('createstrategytxt') }}
          </p-button>

          <button class="btn btn-success fix-margin" v-if="!isNewStrategy" v-on:click="updateStrategy()">
            <i class="fa fa-save"></i>
            {{ $t('upentrybtn') }}
          </button>
        </div>
      </div>

    </div>
  </module>
</template>
<script>
import Vue from 'vue'
import {Tooltip, Table, TableColumn, Select, Option} from 'element-ui';
import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
import VueExcelXlsx from "vue-excel-xlsx";
import Constants from "@/assets/constants";
import swal from "sweetalert2";
import publicStrategy from "@/components/Dashboard/Views/Pages/PublicStrategy.vue";

Vue.use(VueExcelXlsx)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)

Vue.use(require('vue-moment'))

const rules = Constants.methods.getRules();
const user_data = JSON.parse(localStorage.getItem('user-info'));
const MAXSTRATCHAR = 30;

export default {
  components: {
    [Tooltip.name]: Tooltip,
    StatsCard
  },
  computed: {
    publicStrategy() {
      return publicStrategy
    },
    getPublicUrlPrefix() {
      return window.location.href.split(window.location.pathname)[0] + '/strat/';
    },
    isValidDescription: function () {
      return !this.pubs || (this.pubs && this.stratdesc && this.stratdesc.length > 0);
    },
    isValidUrl: function () {
      if (!this.pubs) {
        return true;
      }
      const regex = /^[A-Za-z0-9_]+$/;
      return this.straturl && (this.straturl.length > 5 && this.straturl.length < 30 && regex.test(this.straturl));
    },
  },
  data() {
    return {
      strategyVisibilityResponse: '',
      closed: [],
      open: [],
      account: null,
      inprogress: true,
      adminusr: rules.isAdmin || rules.isSupport,
      sortbyTxt: this.$t('sortby'),
      perpageTxt: this.$t('perpage'),
      pollhistory: null,
      ueml: this.adminusr ? this.$route.params.userid : user_data.email,
      sid: null,
      statsCards: [],
      actionlbl: this.$t('action'),
      modal: false,
      modal_alert: false,
      modal_alert_msg: null,
      modal_alert_type: 'danger',
      followers: [],
      msid: null,
      master: null,
      pubs: false,
      stratname: null,
      straturl: null,
      stratdesc: null,
      stratprice: 0,
      modalprice: false,
      aidprice: null,
      percentage: [
        {'prop': 0, 'label': '0%'},
        {'prop': 5, 'label': '5%'},
        {'prop': 10, 'label': '10%'},
        {'prop': 15, 'label': '15%'},
        {'prop': 20, 'label': '20%'},
        {'prop': 25, 'label': '25%'},
        {'prop': 30, 'label': '30%'},
        {'prop': 35, 'label': '35%'},
        {'prop': 40, 'label': '40%'},
        {'prop': 45, 'label': '45%'},
        {'prop': 50, 'label': '50%'}
      ],
      helper_public_pricetxt: this.$t('helper_public_pricetxt'),
      public_pricetxt: this.$t('public_pricetxt'),
      modalinprogress: false,
      funds: null,
      alert_stratname: false,
      updateinprogress: false,
      assetsLoading: false,
      alert_msg: null,
      tradedAssets: null,
      alert_msg_up: null,
      inprogresstxt: this.$t('loadingstratdeets'),
      canConvertTooltip: '',
      minimum_balance: 0,
      performance_type: 'Equity',
      hoko_performance_fee: 10,
      isNewStrategy: this.$route.path.includes("add"),
      accounts: [],
      canBePublic: false,
      masterAccount: null,
      txt: {
        titleEdit: this.$t('editstrat'),
        titleNewStrat: this.$t('createstrategy'),
        selectMaster: this.$t('choosemasteraccount'),
        helperStratName: this.$t('helper_stratname'),
      },
      validatingForm: false,
      modelValidations: {
        strat_name: {
          required: true,
          regex:/^[a-zA-Z0-9_]*$/
        },
      },
      brokers_in_special_condition: ['XS'],
      internal_credit: 0,
      canEditBrokerInternal: false
    }
  },
  methods: {
    doneAll(resp) {
      this.updateinprogress = false;
      if (!resp.success) {
        this.failop(resp)
      } else {
        this.assetsLoading = false;
        swal({
          title: this.$t("successtxt"),
          text: this.pubs ? this.$t('public_strategy_created') : this.$t('private_strategy_created'),
          type: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(this.prevPage)
      }
    },
    addDone(resp) {
      this.assetsLoading = true;
      if (this.adminusr && this.sid) {
        this.$updateAsset(this.sid, this.tradedAssets)
          .then(this.doneAll, this.failop);
      } else {
        this.doneAll(resp);
      }
    },
    getError (fieldName) {
      return this.errors.first(fieldName);
    },
    validateCreateForm() {
      this.validatingForm = true;

      const proceedCreate = resp => {
        this.validname = resp.success

        if (!this.validname) {
          this.validatingForm = false
          this.$toast.warning(this.$t('choosedifferentstratname'))
        } else {
          if (!this.validname) {
            this.$toast.warning(this.$t('choosedifferentstratname'))
          } else if (!this.msid || !this.stratname) {
            this.$toast.warning(this.$t('emptyfields'));
          } else if (this.pubs && (!this.isValidDescription || !this.isValidUrl)) {
            this.$toast.warning(this.$t('emptyfields'));
          } else {
            this.createStrategy()
          }
        }
        this.validatingForm = false
      }

      this.$checkStratName(this.stratname)
        .then(proceedCreate, this.failCheck);
    },
    failCheck(resp) {
      let error = resp
      if (error === "Mismatched UID and AID") {
        error = 'notallowedtoseehistory';
      } else if (error && error.msg) {
        error = error.msg;
      } else {
        error = 'unkownerror';
      }
      this.$toast.error(this.$t(error))
      this.validatingForm = false
      this.inprogress = false;
      this.updateinprogress = false;
    },
    createStrategy() {
      this.validatingForm = false

      let ujson = this.validateSubmitData();
      ujson["master_id"] = this.msid;
      ujson["shared"] = this.pubs;

      this.$upsertStrategy(null, ujson)
        .then(this.addDone, this.failop);
    },
    updateStrategy() {
      if (!this.isValidDescription || !this.isValidUrl) {
        return this.$toast.warning(this.$t('emptyfields'));
      }
      this.updateinprogress = true;
      let ujson = this.validateSubmitData();

      this.$upsertStrategy(null, ujson, this.sid)
        .then(this.addDone, this.failop);

    },
    validateSubmitData() {
      let ujson = {
        "strat_name": this.stratname,
        "strat_url": this.straturl,
        "discoverable": this.pubs,
        "price": this.stratprice,
        "strat_desc": this.stratdesc,
        "minimum_balance": this.pubs ? parseFloat(this.minimum_balance).toFixed(2) : 0,
        "performance_type": this.performance_type,
        "hoko_performance_fee": parseFloat(this.hoko_performance_fee).toFixed(2) / 100,
        "internal_credit": parseInt(this.internal_credit),
      };
      if (!this.pubs) {
        ujson["price"] = 0;
        delete ujson.strat_desc;
      } else {
        ujson["price"] = parseFloat(ujson["price"]) / 100;
      }
      return ujson;
    },
    checkName(e) {
      var regex = /^[A-Za-z0-9\s]+$/;
      //Validate TextBox value against the Regex.
      var isValid = regex.test(this.stratname);
      this.alert_stratname = false;

      if (!isValid) {
        this.alert_stratname = true;
      } else {
        if (this.stratname && this.stratname.length <= MAXSTRATCHAR) {

          const proceedCreate = resp => {
            if (!resp.data.available) {
              this.$toast.warning(this.$t('choosedifferentstratname'))
              this.validname = resp.success;
            }
            this.alert_stratname = !resp.success;
            this.validname = resp.success;
          };

          this.$checkStratName(this.stratname)
            .then(proceedCreate, this.failop);

        } else {
          this.alert_stratname = true;
        }
      }
    },
    getStrategy() {
      if (this.adminusr && this.$route.params.userid) {
        this.ueml = this.$route.params.userid;
      } else {
        this.ueml = user_data.email;
      }

      this.$getStrategyForUser(this.ueml, this.sid)
        .then(this.loadStrategy, this.failop);
    },
    loadStrategy(resp) {
      if (!resp.success) {
        this.failop();
        return;
      }
      let strat = resp.data[0]
      this.stratname = strat.strat_name;
      this.straturl = strat.strat_url;
      this.pubs = strat.discoverable;
      this.stratdesc = strat.strat_desc;
      this.stratprice = parseFloat(strat.price) * 100;
      this.minimum_balance = strat.minimum_balance;
      this.performance_type = strat.performance_type;
      this.hoko_performance_fee = parseFloat(strat.hoko_performance_fee) * 100;
      this.internal_credit = strat.internal_credit.toString()
      this.inprogress = false;
      this.tradedAssets = strat.tradedAssets

      if (!this.adminusr) {
        this.strategyCanBe(this.pubs ? 'private' : 'public')
      }

    },
    failop(error) {
      if (error === "Mismatched UID and AID") {
        error = this.$t('notallowedtoseehistory');
      } else if (error && error.data && error.data.msg) {
        error = this.$t(error.data.msg);
      } else {
        error = this.$t('unkownerror');
      }
      this.$toast.error(error)
      this.inprogress = false;
      this.updateinprogress = false;
      this.assetsLoading = false;
    },
    prevPage() {
      if (this.adminusr) {
        this.$router.push('/admin_accounts/' + this.$route.params.userid);
      } else {
        this.$router.push('/accounts/main');
      }
    },
    strategyCanBe(type) {
      if (!this.adminusr) {
        let param = {
          'account': this.masterAccount,
          'type': type
        }
        this.$checkStrategyVisibility(param)
          .then(resp => {
            if (!resp.success) {
              this.strategyVisibilityResponse = this.$t(resp.msg)
            }
            this.inprogress = false;
          }, resp => {
            if (resp.msg) {
              this.strategyVisibilityResponse = this.$t(resp.msg)
            } else {
              this.strategyVisibilityResponse = this.$t('strategynotallowedtobepublic');
            }
          })
      }
      this.inprogress = false;
    },
    checkBrokerCondition(broker) {
      if(this.brokers_in_special_condition.includes(broker)) {
        this.hoko_performance_fee = 0.1
      }
    },
    checkBrokerSettings(broker) {
      let success = resp => {
        this.internal_credit = resp.data[0].internal_credit.toString()
        if(resp.data[0].internal_credit) {
          this.canEditBrokerInternal = true
        }
      }

      let failOp = resp => {
        console.log(resp)
      }

      this.$getBrokerSettings(broker).then(success, failOp)
    },
    loadAccount(resp) {
      if (!resp.success) {
        this.failop(resp)
        return;
      }

      this.masterAccount = resp['data'].account;
      this.straturl = resp['data'].url;
      this.checkBrokerSettings(resp['data'].broker)
      if (this.isNewStrategy) {
        this.checkBrokerCondition(resp['data'].broker)
        this.strategyCanBe('public')
      } else {
        this.sid = this.$route.params.sid
        this.getStrategy()
      }
    },
  },
  mounted() {
    this.msid = this.$route.params.aid
    this.$getAccountDetails(this.msid)
      .then(this.loadAccount, this.failop);
  },
  created() {
    this.$setTranslatingInProgress()
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress")
  },
  destroyed() {
    clearInterval(this.pollhistory);
  }
}
</script>
<style lang="scss">
@media only screen and (max-width: 768px) {
  .label {
    font-size: smaller !important;
  }
}

.url-input {
  .input-group-prepend {
    background-color: #f5f5f5;
  }
}

.pre-url {
  font-weight: 400;
  color: #9A9A9A;
  font-size: small;
}

.adjust-width {
  width: 225px;
}

.info-icon {
  position: relative;

  &.nc-alert-circle-i::before {
    vertical-align: middle;
  }
}

.info-t20 {
  top: 20px;
}

.fix-margin {
  margin-right: 5px !important;
}

.el-select .el-input.is-disabled .el-input__inner {
  background-color: #f3f3f3 !important;
}

.form-control[disabled], .form-group .el-input__inner[disabled], .form-control[readonly], .form-group .el-input__inner[readonly], fieldset[disabled] .form-control, fieldset[disabled] .form-group .el-input__inner, .form-group fieldset[disabled] .el-input__inner {
  background-color: #f3f3f3 !important;
}
</style>
